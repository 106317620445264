import '@formatjs/intl-pluralrules/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'promise-polyfill';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { enableES5 } from 'immer';

import { isBrowserIE } from './util/CheckBrowser';

if (isBrowserIE()) {
  // polyfill immer in IE
  enableES5();
}
